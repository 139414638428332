.aMember {
    background-color: $c-light-beige;
    padding: 40px;
    border-radius: 20px;
    box-shadow: none;
    border-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 20px;    
    @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        padding-right: calc($qrcode-ipadsize + $member-pading-ipad + 20px);
    }
    @include media-breakpoint-up(lg) {
        padding: 50px;
        padding-right: calc($qrcode-desktopsize + $member-pading-ipad + 20px);
    }
    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        @include media-breakpoint-up(md) {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
    }
    p {
        margin-bottom: 0;
        color: black;
        text-align: center;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 24px;
        font-family: $ChronicleRoman;
        margin: 0 0 20px 0;
        @include media-breakpoint-up(md) {
            margin: 0;
            text-align: left;
        }
        @include media-breakpoint-up(lg) {
            font-size: 20px;
            line-height: 24px;
            max-width: 400px;
        }
    }
    h2 {
        color: $c-green;
        margin-bottom: 10px;
        font-size: 22px;
        line-height: 1;
        font-family: $Optiker;
        font-weight: 400;
        margin: 0;
        @include media-breakpoint-up(lg) {
            font-size: 40px;
        }
    }
    &__photo {
        width: 185px;
        height: auto;
        border-radius: 50%;
        filter: grayscale(1);
        margin-bottom: 30px;
        @include media-breakpoint-up(md) {
            margin: 0 40px 0 0;
        }
        @include media-breakpoint-up(lg) {
            width: 230px;
            height: 230px;
            margin: 0 70px 0 0;
        }
    }
    a {
        &:hover {
            text-decoration: none;
        }
    }
    a.qrcode-link {
        position: inherit;
        @include media-breakpoint-up(md) {
            position: absolute;
            right: 40px;
        }
        img {
            width: 80px;
            height: 80px;
            @include media-breakpoint-up(md) {
                width: 100px;
                height: 100px;
            }
            @include media-breakpoint-up(lg) {
                width: 150px;
                height: 150px;
            }
        }
    }
}

.team-member-detail-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-bottom: 60px;
    @include media-breakpoint-up(md) {
        padding-left: calc(250px + 50px);
        padding-right: 110px;
        padding-bottom: 60px;
    }
    @include media-breakpoint-up(lg) {
        padding-left: calc(300px + 50px);
        padding-right: 80px;
        padding-bottom: 60px;
        flex-direction: row;
        margin: 60px 0;
        justify-content: center;
    }
    p {
        margin: 0;
        width: 100%;
        text-align: center;
        @include media-breakpoint-up(md) {
            text-align: left;
        }
    }
    a {
        color: black;
        font-size: 20px;
        line-height: 20px;
        font-family: $Optiker;
        text-decoration: none;
        text-align: center;
        padding: 10px 20px;
        border-radius: 50px;
    }
    div.info-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }
    div.big-wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        @include media-breakpoint-up(lg) {
            width: 540px;
        }
    }
    div.cta-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 25px auto;
        @include media-breakpoint-up(lg) {
            margin: 20px auto 8px auto;
        }
        a {
            background-color: $c-green;
            width: 160px;
            text-transform: uppercase;
            @include media-breakpoint-up(md) {
                width: 150px;
            }
        }
    }
    div.cta-wrap-2 {
        width: 100%;
        position: relative;
        a.clear-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border: solid 1px $c-green;
            margin-bottom: 20px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 8px;
            }
            img {
                height: 16px;
                width: auto;
                margin-left: 20px;
            }
        }
    }
    // div.cta-wrap, div.cta-wrap-2{
    //     @include media-breakpoint-up(lg) {
    //     }
    // }
    
    .photo {
        height: 185px;
        width: auto;
        border-radius: 50%;
        filter: grayscale(1);
        @include media-breakpoint-up(md) {
            position: absolute;
            left: 50px;
            margin-top: 20px;
            width: 250px;
            height: 250px;
        }
        @include media-breakpoint-up(lg) {
            width: 300px;
            height: 300px;
            left: 0;
        }
    }
    .name {
        color: $c-green;
        font-family: $Optiker;
        font-weight: 400;
        margin: 20px 0 5px 0;
        width: 80%;
        line-height: 0.85;
        @include media-breakpoint-up(md) {
            text-align: left;
            width: 100%;
        }
        @include media-breakpoint-up(md) {
            margin: 0;
        }
    }
    .title {
        font-family: $ChronicleRoman;
        font-size: 18px;
        line-height: 24px;
        margin: 0 0 20px 0;
       
    }
}
.back-button {
    position: absolute;
    top: 45px;
    right: 40px;
    color: black;
    text-decoration: none;
    font-family: $GraphikRegular;
    font-size: 14px;
    line-height: 1;
    &:visited {
        color: black;
    }
    span {
        text-decoration: none;
        padding-right: 3px;
        font-size: 16px;
        line-height: 14px;
    }
    p {
        text-decoration: underline;
    }
    span, p {
        display: inline-block;
    }
    @include media-breakpoint-up(md) {
        top: 60px;
        right: 60px;
    }
    @include media-breakpoint-up(lg) {
        top: 80px;
        right: 20%; 
    }
    @media(min-width: 2000px) {
        right: 25%;
    }
}

#mybubble {
    padding: 20px;
    background-color: rgb(237, 232, 217, 0.8);
    width: 160px;
    text-align: center;
    font-family: $ChronicleRoman;
    font-size: 14px;
    line-height: 1.5;
    position: absolute;
    right: calc(50% - 80px);
    top: 12%;
    display: none;
    @include media-breakpoint-up(md) {
        top: 15%;
    }
}

.copied {
    background-color: $c-beige;
    padding: 20px;
    width: 100%;
    opacity: 0;
    position: absolute;
    animation: myFadeIn 3s;
    font-family: $ChronicleRoman;
    text-align: center !important;
}

@keyframes myFadeIn {
    0% { opacity: 0; }
    50% { opacity: 0.8 }
    100% { opacity: 0; }
}

.map-info {
    padding: 20px;
    width: 160px;
    text-align: center;
    font-family: $ChronicleRoman;
    font-size: 14px;
    line-height: 1.5;
    p {
        color: black;
        font-family: $ChronicleRoman;
    }
    a {
        color: blue;
        text-decoration: underline;
    }
}

.info-window {
    display: none;
}