@font-face {
    font-family: 'GraphikLight';
    src: url('../assets/fonts/GraphikLight.woff') format('woff');
    font-style: normal;
    font-weight: 'normal';
    font-display: swap;
    text-rendering: optimizeLegibility;
}
@font-face {
    font-family: 'GraphikLightItalic';
    src: url('../assets/fonts/GraphikLightItalic.woff') format('woff');
    font-style: normal;
    font-weight: 'normal';
    font-display: swap;
    text-rendering: optimizeLegibility;
}
@font-face {
    font-family: 'GraphikMedium';
    src: url('../assets/fonts/GraphikMedium.woff') format('woff');
    font-style: normal;
    font-weight: 'normal';
    font-display: swap;
    text-rendering: optimizeLegibility;
}
@font-face {
    font-family: 'GraphikRegular';
    src: url('../assets/fonts/GraphikRegular.woff') format('woff');
    font-style: normal;
    font-weight: 'normal';
    font-display: swap;
    text-rendering: optimizeLegibility;
}
@font-face {
    font-family: 'Optiker';
    src: url('../assets/fonts/Optiker-K.woff') format('woff');
    font-style: normal;
    font-weight: 'normal';
    font-display: swap;
    text-rendering: optimizeLegibility;
}
@font-face {
    font-family: 'ChronicleRoman';
    src: url('../assets/fonts/ChronicleDisp-Roman.woff') format('woff');
    font-style: normal;
    font-weight: 'normal';
    font-display: swap;
    text-rendering: optimizeLegibility;
}
@font-face {
    font-family: 'ChronicleRomanMedium';
    src: url('../assets/fonts/ChronicleDisp-Semibold.woff') format('woff');
    font-style: normal;
    font-weight: 'normal';
    font-display: swap;
    text-rendering: optimizeLegibility;
}
@font-face {
    font-family: 'Optician-Sans';
    src: url('../assets/fonts/Optician-Sans.otf') format('otf');
    font-style: normal;
    font-weight: 'normal';
    font-display: swap;
    text-rendering: optimizeLegibility;
}
$GraphikLight: 'GraphikLight', Helvetica, sans-serif;
$GraphikMedium: 'GraphikMedium', Helvetica, sans-serif;
$GraphikLightItalic: 'GraphikLightItalic', Helvetica, sans-serif;
$GraphikRegular: 'GraphikRegular', Helvetica, sans-serif;
$Optiker: 'Optiker', Helvetica, sans-serif;
$ChronicleRoman: 'ChronicleRoman', Helvetica, sans-serif;
$ChronicleRomanMedium: 'ChronicleRomanMedium', Helvetica, sans-serif;
$OpticianSans: 'Optician Sans', Helvetica, sans-serif;