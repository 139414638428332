.loader {
    height: 20px;
    width: 180px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.loader--dot {
    animation-name: loader;
    animation-timing-function: ease-in-out;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: black;
    position: absolute;
    border: 1px solid white;
}
.loader--dot:first-child {
    background-color: #dad7cd;
    animation-delay: 0.4s;
}
.loader--dot:nth-child(3) {
    background-color: #a3b18a;
    animation-delay: 0.3s;
}

.loader--dot:nth-child(4) {
    background-color: #588157;    
    animation-delay: 0.2s;
}
.loader--dot:nth-child(5) {
    background-color: #606c38;
    animation-delay: 0.1s;
}
.loader--dot:nth-child(6) {
    background-color: #344e41;
    animation-delay: 0s;
}
.loader--text {
    position: absolute;
    top: 200%;
    left: 0;
    right: 0;
    width: 4rem;
    margin: auto;
    color: #344e41;
}
.loader--text:after {
    content: "LOADING";
    font-weight: bold;
    text-transform: uppercase;
    animation-name: loading-text;
    animation-duration: 1.8s;
    animation-iteration-count: infinite;
    color: #344e41;
}

@keyframes loader {
    15% {
        transform: translateX(0);
    }
    48% {
        transform: translateX(180px);
    }
    95% {
        transform: translateX(0);
    }
}
@keyframes loading-text {
    0% {
        content: "LOADING";
    }
    25% {
        content: "LOADING.";
    }
    50% {
        content: "LOADING..";
    }
    75% {
        content: "LOADING...";
    }
}